import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { SocketSelector } from "../../store/slices/SocketSlice";
import styles from '../UserVideo/uservideo.module.scss'
import ReactPlayer from 'react-player'
import Loader from "../Loader";
import usePeer from "../../hooks/usePeer";
import { MessageSelector } from "../../store/slices/MessageSlice";
const ServerVideo = ({ remoteVideo,peer}) => {
  const {isSearching}=useSelector(MessageSelector)
  useEffect(()=>{
    if (peer) {
      console.log({remoteVideo})
      if (!!remoteVideo.current && !isSearching) {
        peer.ontrack = (event) => {
          let stream = event.streams;
          console.log({ stream });
          if (stream) {
            remoteVideo.current.srcObject = stream[0];
            // setRemoteStream(stream[0]);
          }
        };
      }
    }
  },[peer, isSearching])
  return (
    <div className={styles.container}>
      {isSearching && <Loader />}
      {!isSearching &&   <video  ref={remoteVideo} autoPlay playsInline /> }
    
    </div>
  );
};

export default ServerVideo;

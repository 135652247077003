import logo from "./logo.svg";
import "./App.scss";
import AllRoutes from "./routers/Routes";
import CameraStream from "./component/UserVideo/index";
import socketIO from 'socket.io-client';
import { useEffect } from "react";
import Home from "./pages/Home";
import { SocketProvider } from "./context/Socket";
function App() {

  return (
    <SocketProvider >
    <div className="App">
     <Home />
      {/* <AllRoutes /> */}
    </div>
    </SocketProvider>
  );
}

export default App;

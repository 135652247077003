import React, { useCallback, useRef, useState } from "react";
import styles from "./home.module.scss";

import { useEffect } from "react";
import Logo from "../../Assets/logo.png";
import ServerVideo from "../../component/ServerVideo";
import CameraStream from "../../component/UserVideo/index";
import usePeer from "../../hooks/usePeer";
import { useSocket } from "../../context/Socket";
import ChatSection from "../../component/ChatSection";
import useChat from "../../hooks/useChat";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MessageSelector, updateActive } from "../../store/slices/MessageSlice";
const Home = () => {
  const remoteVideo = useRef(null);
  const [remoteStream, setRemoteStream] = useState();
  const { active } = useSelector(MessageSelector);
  const socket = useSocket();
  const dispatch = useDispatch();
  // const [socket, setSocket] = useState();
  // const [peer, setPeer] = useState();
  // const [remoteSocket, setRemoteSocket] = useState("");
  const {
    handleCloseConnection,
    IncomingAnswer,
    IncomingCall,
    NegoFinal,
    NegoNeeded,
    closeConnection,
    connectPeer,
    createCall,
    handleNego,
    remoteUser,
    stopConnection,
    peer,
    sendStream,
  } = usePeer();
  const { MessageReceived, SendMessage } = useChat();
  const NextUser = () => {
    closeConnection();
    // handleCloseConnection()
  };
  const handleNextUser = () => {
    console.log("calllled");
    handleCloseConnection();
  };
  useEffect(() => {
    if (socket) {
      socket.on("incoming:call", IncomingCall);
      socket.on("incomming:answere", IncomingAnswer);
      socket.on("nego:needed", NegoNeeded);
      socket.on("nego:final", NegoFinal);
      socket.on("disconnected", handleNextUser);
      socket.on("chat:received", MessageReceived);
    }
    return () => {
      if (socket) {
        socket.off("incoming:call", IncomingCall);
        socket.off("incomming:answere", IncomingAnswer);
        socket.off("nego:needed", NegoNeeded);
        socket.off("nego:final", NegoFinal);
        socket.off("disconnected", handleNextUser);
        socket.off("chat:received", MessageReceived);
      }
    
    };
  }, [
    socket,
    IncomingAnswer,
    IncomingCall,
    NegoFinal,
    NegoNeeded,
    handleCloseConnection,
  ]);
  useEffect(() => {
    if (peer) {
      peer.onicegatheringstatechange = () => {
        console.log("Ice gathering state is ", peer.iceGatheringState);
        if (peer.iceGatheringState === "gathering") {
          sendStream();
        }
      };
    }
  }, [peer]);
  useEffect(() => {
    if (peer) {
      peer.addEventListener("negotiationneeded", handleNego);
      return () => {
        peer.addEventListener("negotiationneeded", handleNego);
      };
    }
  }, [handleNego, peer]);
  useEffect(() => {
    connectPeer();
    return ()=>{
      closeConnection();
    }
  }, []);
  const startSearching = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (stream) {
          console.log("hahahaha");
          dispatch(updateActive(true));
          createCall(peer);
        })
        .catch(function (error) {
          console.log(error);
          alert("Please enable microphone and camera");
        });
    } else {
      // getUserMedia is not supported
      alert("Please enable microphone and camera");
      return false;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={Logo} />
        <h1>TalkRandom</h1>
        <h6>
          <span></span>234321 Online
        </h6>
      </div>
      <div className={styles.body}>
        <div className={styles.streams}>
          <CameraStream startSearching={startSearching} />
          {Boolean(active) && (
            <ServerVideo remoteVideo={remoteVideo} peer={peer} />
          )}
        </div>
      </div>
      {Boolean(active) && (
        <div className={styles.footer}>
          <div className={styles.buttonsDiv}>
            {active && (
              <button className={styles.start} onClick={NextUser}>
                Next
              </button>
            )}
            {
              <button className={styles.stop} onClick={stopConnection}>
                Stop
              </button>
            }
          </div>
          {/* <input value={chat} onChange={handleChange} /> */}
          {/* <button onClick={handleSendMessage}>Send</button> */}
          <ChatSection remoteUser={remoteUser} />
        </div>
      )}
    </div>
  );
};

export default Home;

import React, { createContext, useCallback, useContext, useMemo } from "react";
import { io } from "socket.io-client";
//https://omegle-9py3.onrender.com
const SocketContext = createContext(null);
export const SocketProvider = (props) => {
  const socket = useMemo(() => io("https://omegle-9py3.onrender.com"), []);
  return <SocketContext.Provider value={socket}>{props.children}</SocketContext.Provider>;
};
export const useSocket = () => {
  let socket = useContext(SocketContext);
  return socket;
};

import { createSlice } from "@reduxjs/toolkit";

export const firebaseSlice=createSlice({
    name:'firebaseSlice',
    initialState:{
        firebaseDb:null
    },
    reducers:{
        updateDb:(state,{payload})=>{
            state.firebaseDb=payload

        }
    }
})
export const {updateDb}=firebaseSlice.actions
export default firebaseSlice.reducer;
export const firebaseSelector=(state)=>state.firebaseSelector
import React from "react";
import styles from "./StartModal.module.scss";
import ReactPlayer from "react-player";
import usePeer from "../../hooks/usePeer";
import { useDispatch } from "react-redux";
const StartModal = ({startSearching}) => {
    const {peer ,createCall}=usePeer()
    const dispatch=useDispatch()
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.video}>
          <video
            autoPlay
            muted
            loop
            src="https://video-delivery.x-point-of-entry.com/ome.tv/English%20(U-S-)%202.mp4"
          ></video>
          {/* <ReactPlayer height={'100'} playing playsinline muted url={'https://video-delivery.x-point-of-entry.com/ome.tv/English%20(U-S-)%202.mp4'} /> */}
          {/* <iframe    allowFullScreen src='https://www.youtube.com/embed/kNCxJrxM6Ms?mute=0&controls=0&autoplay=1&loop=1&hl=ar&playlist=kNCxJrxM6Ms&suggestedQuality=highres&widget_referrer=https%3A%2F%2Fometv.chat%2F&enablejsapi=1&origin=https%3A%2F%2Fometv.chat&widgetid=1' /> */}
        </div>
        <div className={styles.info}>
          <div className={styles.heading}> Be a member of TalkRandom</div>
          <div className={styles.tagline}>
            Discover the thrill of random video chat with TalkRandom — the best
            Omegle alternative!
          </div>
          <button className={styles.start} onClick={startSearching}>Start</button>
          <div className={styles.terms}>
            I confirm that I am at least 18 years old. I accept User Agreement.
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartModal;

import { useCallback, useState } from "react";
import { useSocket } from "../context/Socket";
import { useDispatch } from "react-redux";
import { clearMessages, updateActive, updateSearching } from "../store/slices/MessageSlice";

const usePeer = () => {
  const socket = useSocket();
  const [peer, setPeer] = useState();
  const [remoteSocket, setRemoteSocket] = useState();
  const [remoteUser, setRemoteUser] = useState();
  const [myStream, setMyStream] = useState();
  const [isSearching,setIsSearching]=useState(false)
  const dispatch=useDispatch()
  const iceServers = {
    iceServers: [
      {
        urls: "stun:stun.stunprotocol.org",
      },
    ],
  };
  const createCall = useCallback(async (peer) => {
    let localOffer = await peer.createOffer();
    dispatch(updateSearching(true))
    await peer.setLocalDescription(new RTCSessionDescription(localOffer));
    socket.emit("searching", { from: localOffer });
    console.log('calllled')
    // const mySteam = await navigator.mediaDevices.getUserMedia({
    //   video: true,
    //   audio: true,
    // });
    // setMyStream(mySteam);
  }, [peer, socket]);
  const closeConnection = useCallback(() => {
    if (peer) {
      peer.close();
      if (remoteUser) {
        socket.emit("disconnectPeer", { to: remoteUser });  
      }
      const newpeer = new RTCPeerConnection(iceServers);
      createCall(newpeer);
      setPeer(newpeer)
      dispatch(clearMessages())
    }
  }, [peer, createCall, remoteUser, socket]);

  const connectPeer = () => {
    // closeConnection()
    const peer = new RTCPeerConnection(iceServers);
    setPeer(peer);
    return peer
  };
  const sendStream = useCallback(async () => {
    const myStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    for (const track of myStream.getTracks()) {
      peer.addTrack(track, myStream);
    }
  }, [peer]);
  const IncomingCall = useCallback(
    async (data) => {
      const { from, offer } = data;
      setRemoteUser(from);
      dispatch(updateSearching(false))
      await peer.setRemoteDescription(new RTCSessionDescription(offer));
      const answereOffer = await peer.createAnswer();
      await peer.setLocalDescription(new RTCSessionDescription(answereOffer));
      socket.emit("call:accepted", { answere: answereOffer, to: from });
      sendStream();
    },
    [peer, sendStream, socket]
  );
  const IncomingAnswer = useCallback(
    async (data) => {
      const { offer, from } = data;
      setRemoteSocket(data?.from);
      dispatch(updateSearching(false))
      setRemoteUser(from);
      await peer.setRemoteDescription(offer);
      sendStream();
    },
    [sendStream, peer]
  );
  const NegoNeeded = useCallback(
    async (data) => {
      const { from, offer } = data;
      await peer.setRemoteDescription(offer);
      const answereOffer = await peer.createAnswer();
      await peer.setLocalDescription(new RTCSessionDescription(answereOffer));
      socket.emit("nego:done", { to: from, offer: answereOffer });
    },
    [peer, socket]
  );
  const NegoFinal = useCallback(
    async ({ offer }) => {
      await peer.setRemoteDescription(offer);
    },
    [peer, sendStream]
  );

  const handleCloseConnection = useCallback(() => {
    if (peer) {
      const newpeer = new RTCPeerConnection(iceServers);
      createCall(newpeer);
      setPeer(newpeer)
      dispatch(updateSearching(true))
      dispatch(clearMessages())
    }
  }, [peer, createCall]);
  const stopConnection=useCallback(()=>{
    if (peer) {
      peer.close();
      if (remoteUser) {
        socket.emit("disconnectPeer", { to: remoteUser });  
      }
      socket.emit('seaching:stop')
      dispatch(updateActive(false))
      const newpeer = new RTCPeerConnection(iceServers);
      setPeer(newpeer)
      dispatch(clearMessages())
    }
  
  },[peer,closeConnection, remoteUser])
  const handleNego = useCallback(async () => {
    // console.log("ffffff", remoteSocket);
    if (remoteSocket?.length > 0) {
      let localOffer = await peer.createOffer();
      // console.log({ localOffer });
      // setPeer(peer)
      await peer.setLocalDescription(new RTCSessionDescription(localOffer));
      socket.emit("nego:need", { socketId: remoteSocket, offer: localOffer });
    }
  }, [remoteSocket, peer, socket]);

  return {
    peer,
    NegoFinal,
    NegoNeeded,
    handleNego,
    IncomingAnswer,
    IncomingCall,
    createCall,
    connectPeer,
    closeConnection,
    handleCloseConnection,
    sendStream,
    myStream,
    remoteSocket,
    remoteUser,
    isSearching,
    stopConnection
  };
};

export default usePeer;

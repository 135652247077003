import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./uservideo.module.scss";
import { SocketSelector } from "../../store/slices/SocketSlice";
import { MessageSelector } from "../../store/slices/MessageSlice";
import StartModal from "../StartModal";
import usePeer from "../../hooks/usePeer";
const CameraStream = ({startSearching}) => {
  const {stopConnection}=usePeer()
  const videoRef = useRef(null);
  const [videoOn, setVideoOn] = useState(false);
  const {active}=useSelector(MessageSelector)
  useEffect(() => {
    const startCamera = async () => {
      try {
        // Get user media (camera and audio)
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.oninactive=()=>{
          stopConnection()
        }
        // Set the stream as the source for the video element
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setVideoOn(true);
        }
      } catch (error) {
        console.error("Error accessing camera and microphone:", error);
      }
    };

    startCamera();

    // Cleanup function to stop the stream when the component unmounts
    return () => {
      if (videoRef.current) {
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        setVideoOn(false);
      }
    };
  }, [active]);

  return (
    <div className={`${styles.user_container} ${active ? styles.mobile: null} `}>
      {active ? <video ref={videoRef} autoPlay playsInline /> :    <StartModal startSearching={startSearching} />}
      {/* You can also add an audio element if you want to include audio */}
    </div>
  );
};

export default CameraStream;

import { createSlice } from "@reduxjs/toolkit";

export const SocketSLice=createSlice({
    name:'SocketSlice',
    initialState:{
        peer:null,
        socket:null,
    },
    reducers:{
        updateSocket:(state,{payload})=>{
          state.socket=payload
        },
        updatePeer:(state,{payload})=>{
            state.peer=payload
        }
    }
})
export const {updatePeer,updateSocket} =SocketSLice.actions
export default SocketSLice.reducer
export const SocketSelector=(state)=>state.socketSelector
import React, { useState } from "react";
import { useSocket } from "../context/Socket";
import { useDispatch } from "react-redux";
import { updateMessage } from "../store/slices/MessageSlice";

const useChat = () => {
    const dispatch=useDispatch()
  const socket = useSocket();
  function SendMessage(text, remoteUser) {
    let date = new Date();
    let message = {
      time: date.getTime(),
      text: text,
    };
   dispatch(updateMessage(message))
    if (socket) {
      socket.emit("send:chat", { to: remoteUser, message });
    }
  }
  function MessageReceived(message) {
    if (message) {
      console.log("Received message", message);
      dispatch(updateMessage(message))
    }
  }
  return {  SendMessage, MessageReceived };
};

export default useChat;

import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "LoginSlice",
  initialState: {
    isAuthenticated: false,
    token: "",
    userData: "",
  },
  reducers: {
    updateToken: (state, { payload }) => {
      state["token"] = payload;
    },
    updateAuthentication: (state, { payload }) => {
      state["isAuthenticated"] = Boolean(payload);
    },
    updateUserData: (state, { payload }) => {
      state["userData"] = payload;
    },
  },
});
export const {updateAuthentication,updateToken,updateUserData}=loginSlice.actions
export default loginSlice.reducer
export const loginSelector=(state)=>state.loginSelector
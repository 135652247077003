import { createSlice } from "@reduxjs/toolkit";

export const MessageSlice=createSlice({
    name:'MessageSlice',
    initialState:{
        messages:[],
        isSearching:false,
        active:false,
    },
    reducers:{
        updateMessage:(state,{payload})=>{
            state.messages=[...state.messages, payload]
        },
        clearMessages:(state,{payload})=>{
            state.messages=[]
        },
        updateSearching:(state,{payload})=>{
            state.isSearching=payload
        },
        updateActive:(state,{payload})=>{
            state.active=payload
        }
    }
})
export const {clearMessages,updateMessage,updateSearching, updateActive}=MessageSlice.actions
export default MessageSlice.reducer
export const MessageSelector=(state)=>state.MessageSelector

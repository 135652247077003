import { configureStore } from '@reduxjs/toolkit';
import loginslice from './slices/loginslice';
import FirebaseSlice from './slices/firebaseSlice'
import SocketSelector from './slices/SocketSlice'
import MessageSelector from './slices/MessageSlice'
export default configureStore({
    reducer:{
        loginSelector:loginslice,
        socketSelector:SocketSelector,
        MessageSelector:MessageSelector
    },
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false})
    
})
import React from "react";
import "./loader.scss";
const Loader = () => {
  return (
    <div className="loading">
      <div class="cup">
        <div class="handle"></div>
      </div>
    </div>
  );
};

export default Loader;

import React, { useCallback, useState } from "react";
import styles from "./chatsection.module.scss";
import useChat from "../../hooks/useChat";
import { useSelector } from "react-redux";
import { MessageSelector } from "../../store/slices/MessageSlice";
import usePeer from "../../hooks/usePeer";
const ChatSection = ({remoteUser}) => {
  const { messages ,isSearching} = useSelector(MessageSelector);
  const [chat, setChat] = useState("");
  const {SendMessage}=useChat()
//   const {remoteUser}=usePeer()
  const handleChange = (event) => {
    const { value } = event.target;
    setChat(value);
  };
  
  const handleSendMessage =useCallback( (e) => {
    if (e.key=='Enter') {
        console.log({chat})
        SendMessage(chat, remoteUser);
        setChat("");
        console.log('hahahha')
    }
   
  },[remoteUser,chat]);

  return (
    <div className={styles.main}>
       
      {Array.isArray(messages) &&
        messages.map((message) => (
          <div
            className={`${styles.message} ${
              message.from === "server" ? "" : styles.self
            }`}
          >
            {message.text}
          </div>
        ))}
      {!isSearching &&  <input value={chat} onChange={handleChange} onKeyDown={handleSendMessage} placeholder="Type your message here" />}
    </div>
  );
};

export default React.memo(ChatSection);
